@import '~@angular/material/theming';
@include mat-core();


$custom-typography: mat-typography-config($font-family: "Roboto");
@include mat-core($custom-typography);

/* ======== Angular material custom themes ======== */ 
$my-custom-primary: mat-palette($mat-grey);
$my-custom-accent: mat-palette($mat-grey, 100, 500, A100);
$my-custom-warn: mat-palette($mat-grey);

$my-custom-theme: mat-light-theme($my-custom-primary, $my-custom-accent, $my-custom-warn);

@include angular-material-theme($my-custom-theme);

