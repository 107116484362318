@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "~@angular/material/theming";

@include mat-core();

.alternate-theme {
  $alternate-primary: mat-palette($mat-grey, 600);
  $alternate-accent: mat-palette($mat-grey, 200);
  $alternate-warn: mat-palette($mat-grey);

  $alternate-theme: mat-light-theme(
    $alternate-primary,
    $alternate-accent,
    $alternate-warn
  );

  @include angular-material-theme($alternate-theme);
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
}

button:focus {
  outline: 0 !important;
}
div:focus {
  outline: 0;
}

body {
  background-color: rgb(254, 254, 254);
}

.ql-editor {
  padding: 0 !important;
  font-family: "roboto";
  font-size: 16px;
}

.ql-editor p {
  margin: auto !important;
  line-height: 2rem;
}

.ql-editor a {
  color: #3f7bb1;
}

.ql-editor a:hover {
  text-decoration: underline !important;
}

.ql-editor code {
  background-color: #f5f5f5;
  color: red;
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 4px;
}

.ql-editor li::before {
  font-size: 20px !important;
}

.ql-editor pre {
  background-color: #eeeeee;
  border-radius: 4px;
  border: 1px solid #aaaaaa;
  padding: 8px;
}

.ql-blank::before {
  top: 20px !important;
  left: 16px !important;
  font-family: "Roboto";
  font-weight: 500 !important;
  color: #b4b4b4 !important;
  font-style: normal !important;
  @media (max-width: 639px) {
    left: 32px !important;
  }
}

.mat-dialog-container {
  background: transparent !important;
  color: unset !important;
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: inherit !important;
}

.mat-calendar-body-cell {
  color: inherit !important;
}

.mat-calendar-body-cell-content {
  color: inherit !important;
}

.darkBackdrop {
  background: rgba(255, 255, 255, 0.32) !important;
}
